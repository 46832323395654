<template>
    <div class="row row-deck row-cards">
        <div class="col">
            <SessionList />
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

const SessionList = defineAsyncComponent(() =>
    import('@/components/Session/SessionList.vue')
)
</script>
