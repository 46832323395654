<template>
    <router-link :to="{ 'name': 'session-index' }" class="d-inline-flex align-items-center" role="button">
        <icon name="chevron-left" />
        <span>Back to Sessions</span>
    </router-link>
    <overlay-spinner :is-showing="isFetching" v-if="isFetching" />
    <div v-else-if="!session" class="mt-4 d-flex justify-content-center py-4  ">
        <p class="text-center text-danger fw-bold text-uppercase py-4 px-4 rounded">
            Session does not exist
        </p>
    </div>
    <template v-else>
        <div class="page-header ">
            <div class="row align-items-center justify-content-between  ">
                <div class="col-sm-12 col-md-11 d-flex align-items-center mb-3">

                    <CopyText :data="session.no" resource-name="Session No" style="min-width: 13rem">
                        <h2 class="page-title text-gray-800">
                            {{ session.no }}
                        </h2>
                    </CopyText>

                    <CopyButton :data="getShowSessionPath(session.id)" resource-name="Session URL" class="mx-1"
                        transparent />

                    <span class="badge badge-pill" :class="[getSessionStatusBadge(session.sessionStatusId)]">
                        {{ getSessionStatusLabel(session.sessionStatusId) }}
                    </span>
                    <span class="badge badge-pill bg-red" v-if="session.isUrgent">
                        Urgent
                    </span>
                </div>
                <div class="col-sm-12 col-md-1">

                    <!-- Edit Session Details Modal -->
                    <div class="btn-with-tooltip">
                        <button @click="openEditSessionModal(session)" class="btn btn-icon btn-outline">
                            <icon name="pencil" />
                        </button>
                        <span class="tooltip">Edit Session</span>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="activeComponent">
            <sub-menu :items="subMenus" :active="activeComponent"></sub-menu>

            <div class="py-4">
                <KeepAlive>
                    <component :is="activeComponent.component" v-bind="activeComponent.props"
                        :key="`${activeComponent.slug}-${Date.now()}`">
                    </component>
                </KeepAlive>
            </div>
        </template>


    </template>
</template>

<script setup lang="ts">
import { getSessionStatusBadge, getShowSessionPath } from '@/composable/useSession'
import { useNotificationsStore } from '@/stores/notifications'
import { useResourcesStore } from '@/stores/resources'
import SessionStatus from '@/types/SessionStatus'
import { storeToRefs } from 'pinia'
import { computed, defineAsyncComponent, markRaw, onMounted, provide, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useShowSessionStore } from '@/stores/showSession'
import SubMenu from '@/components/SubMenu.vue'
import CopyButton from '@/components/CopyButton.vue'
import CopyText from '@/components/CopyText.vue'
import SessionEditFormModal from '@/pages/sessions/SessionEditFormModal.vue'
import Session from '@/types/Session'
import { useModalStore } from '@/stores/modalStore'
import { useSessionStore } from '@/stores/sessionStore'
const { sessionStatuses } = storeToRefs(useResourcesStore())

const showSessionStore = useShowSessionStore()
const {
    sessionId,
    session,
    isFetching,
    activeComponent,
    subMenus
} = storeToRefs(showSessionStore)

const { fetchSession, setActiveComponent, configureSubMenu } = showSessionStore

const route = useRoute()

const modalStore = useModalStore()
const { showModal, hideModal } = modalStore

const sessionStore = useSessionStore()
const { currentSession } = storeToRefs(sessionStore)

provide('setActiveComponent', setActiveComponent)

sessionId.value = Number(route.params.sessionId)

fetchSession()

const getSessionStatusLabel = (statusId: number) => {
    const sessionStatus = sessionStatuses.value.find((sessionStatus: SessionStatus) => sessionStatus.id === statusId)
    return sessionStatus?.adminLabel ?? 'N/A'
}


const openEditSessionModal = (session: Session) => {
    currentSession.value = session

    showModal({
        component: markRaw(defineAsyncComponent(() =>
            import('@/pages/sessions/SessionEditFormModal.vue')
        )),
        title: `Session ${session.no}`,
        size: 'xl',
        props: {
            onSessionUpdated: () => {
                fetchSession()
                hideModal()
            }
        }
    })
}

const initListeners = () => {
    window.Echo?.private('backoffice')
        .listen('.SessionUpdated', ({ model }: any) => {
            if (model.id === session.value?.id) {
                session.value = model
            }
        })
}

watch(() => session.value, () => {
    configureSubMenu()
    setActiveComponent('')
})

initListeners()

</script>