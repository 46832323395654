import { defineStore } from 'pinia'
import { computed, defineAsyncComponent, markRaw, ref } from 'vue'
import Session from '@/types/Session'
import SessionService from '@/services/SessionService'
import { useNotificationsStore } from '@/stores/notifications'
import { SessionIncludes } from '@/types/query/SessionIncludes'


export const useShowSessionStore = defineStore(
    'showSession',
    () => {

        const { addToastNotification } = useNotificationsStore()

        const sessionId = ref<number>()
        const session = ref<Session>()

        const activeComponent = ref<object>({})
        const subMenus = ref<Array<any>>([])

        const isFetching = ref<boolean>(false)
        const setIsFetching = (value = true) => isFetching.value = value

        const fetchSession = async (reloadComponentSlug: string = '') => {
            setIsFetching()

            if (!sessionId.value) {
                return
            }

            try {
                const includes: SessionIncludes[] = ['all']

                const { data: { data } } = await SessionService.show(sessionId.value, includes)
                session.value = data
                configureSubMenu()
                setActiveComponent(reloadComponentSlug)
            } catch (e) {
                addToastNotification({
                    message: `Failed to fetch session #${sessionId} due to internal server error. Please contact tech team.`,
                    type: 'danger'
                })
            }

            setIsFetching(false)
        }

        const setActiveComponent = (menu: any = null) => {
            if (typeof menu === 'string' && menu.length !== 0) {
                activeComponent.value = subMenus.value.find(
                    (subMenu) => subMenu.slug === menu
                )
            } else if (typeof menu === 'object') {
                activeComponent.value = menu
            } else {
                activeComponent.value = subMenus.value[0]
            }
        }

        const configureSubMenu = () => {
            subMenus.value = [
                {
                    title: 'Overview',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionOverview.vue')
                    ),
                    slug: 'session-overview',
                    props: {
                        session: session.value
                    }
                },
                {
                    title: 'Orders',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionOrders.vue')
                    ),
                    slug: 'session-orders',
                    props: {
                        session: session.value,
                        mainOrder: session.value?.order,
                        extraOrders: session.value?.orders
                    }
                },
                {
                    title: 'Dependents',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionDependents.vue')
                    ),
                    slug: 'session-dependents',
                    props: {
                        dependents: session.value?.dependents ?? []
                    }
                },
                {
                    title: 'Carer Applications',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionCarerApplications.vue')
                    ),
                    slug: 'session-carer-applications',
                    props: {
                        session: session.value,
                        preferredCarers: session.value?.preferredCarers
                    }
                },
                {
                    title: 'Rates & Payouts',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionRates.vue')
                    ),
                    slug: 'session-rates',
                    props: {
                        rates: session.value?.rates ?? [],
                        totalRates: session.value?.totalRates,
                        payRates: session.value?.payRates,
                        totalPayRates: session.value?.totalPayRates
                    }
                },
                {
                    title: 'Review',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionReview.vue')
                    ),
                    slug: 'session-review',
                    props: {
                        sessionId: session.value?.id
                    }
                },
                {
                    title: 'Check List',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionChecklist.vue')
                    ),
                    slug: 'session-check-list',
                    props: {
                        document: session.value?.document
                    }
                },
                {
                    title: 'Session Events',
                    component: defineAsyncComponent(
                        () => import('@/components/SessionEventsTimeline/SessionEventsTimeline.vue')
                    ),
                    slug: 'session-events',
                    props: {
                        sessionId: session.value?.id,
                        currentEvent: computed(() => session.value?.event),
                        onUpdated: async (newEvent: Event) => {

                            if (!session.value) {
                                addToastNotification({
                                    'type': 'danger',
                                    'message': 'Error fetching updated session details: Invalid ID '
                                })
                                return
                            }

                            const includes: SessionIncludes[] = ['event', 'carerLogs']

                            try {
                                const { data } = await SessionService.show(session.value.id, includes)
                                session.value.event = data.data.event
                                session.value.carerLogs = data.data.carerLogs

                            } catch (err) {
                                addToastNotification({
                                    'type': 'danger',
                                    'message': 'Error fetching session details:' + err
                                })

                            }
                        }
                    }
                },
                {
                    title: 'Carer Logs',
                    component: defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionCarerLogs.vue')
                    ),
                    slug: 'session-carer-logs',
                    props: {
                        carerLogs: session.value?.carerLogs,
                        includeImage: true
                    }
                },
                {
                    title: 'Change Logs',
                    component: markRaw(defineAsyncComponent(
                        () => import('@/pages/sessions/show/SessionActivityLogs/SessionActivityLog.vue')
                    )),
                    slug: 'session-activity-log',
                    props: {
                        activityLogs: session.value?.activityLogs,
                        order: session.value?.order
                    }
                }
            ]
        }

        return {
            sessionId,
            session,
            isFetching,
            subMenus,
            activeComponent,
            setActiveComponent,
            configureSubMenu,
            fetchSession
        }
    }
)