<template>
    <button @click="openModal" class="btn btn-icon btn-primary">
        {{ buttonLabel }}
    </button>
    <Teleport to="body">
        <kc-modal :modal-id="modalId" modal-title="Carer On The Way">
            <template #default>
                <p class="text-muted mb-4">
                    Please confirm the carer is on the way to customer's location.
                </p>
                <form class="col">
                    <div class="form-group">
                        <FormLabel label="Expected Time Of Arrival" is-required />
                        <TimePicker input-id="carer-eta" v-model="selectedTime" />
                        <ErrorMessage :has-error="!!errorMessage" :message="errorMessage || ''" />
                    </div>
                </form>
            </template>
            <template #footer>
                <button class="btn btn-secondary me-2" @click="openModal(false)">Cancel</button>
                <button class="btn btn-primary" @click.prevent="submit">Submit</button>
            </template>
        </kc-modal>
    </Teleport>
</template>

<script setup lang="ts">
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import FormLabel from '@/components/form/FormLabel.vue'
import HelperText from '@/components/form/HelperText.vue'
import ImageInput from '@/components/form/ImageInput.vue'
import TimePicker from '@/components/form/TimePicker.vue'
import { useModal } from '@/composable/useModal'
import { SelectedUploadImage } from '@/services/SelectedUploadImage'
import SessionEventService from '@/services/SessionEventService'
import { useNotificationsStore } from '@/stores/notifications'
import { SessionEventTypeEnum } from '@/types/SessionEventTypeEnum'
import { AxiosError } from 'axios'
import { ref } from 'vue'


const { addToastNotification } = useNotificationsStore()

const props = defineProps({
    sessionId: { type: Number, required: true },
    buttonLabel: { type: String, default: 'Proceed' }
})

const emits = defineEmits(['success'])

const modalId = 'carer-on-the-way-form-modal'

const {
    openModal
} = useModal(modalId)

const errorMessage = ref<string | null>()

const selectedTime = ref('09:00 AM')

const submit = async () => {
    if (!selectedTime.value) {
        errorMessage.value = 'Please select an expected time of arrival'
        return
    }

    try {
        const response = await SessionEventService.update(props.sessionId, {
            session_event_type_id: SessionEventTypeEnum.ID_STATUS_LOCATION_START,
            eta: selectedTime.value
        })
        emits('success')
        openModal(false)
    } catch (e) {
        const error = e as AxiosError

        if (error.response?.status === 422) {
            const responseData = error.response.data as {
                errors?: Record<string, string[]>,
                message: string
            }

            errorMessage.value = responseData.message
        } else {
            addToastNotification({
                message: 'Internal server error. Please contact tech team.',
                type: 'danger'
            })
        }
    }
}

</script>


<style scoped>
.upload-container {
    cursor: pointer;
}
</style>