<template>
    <div class="w-100 h-4/5-dvh ">
        <div v-if="isFetchingDetails" class="text-center py-4">
            <div class="spinner-border text-primary"></div>
        </div>

        <template v-else-if="currentSession">
            <ul class="nav nav-tabs card-header-tabs" data-bs-toggle="tabs">
                <li class="nav-item">
                    <a :href="`#tabs-session-events-${currentSession.id}`" class="nav-link active" data-bs-toggle="tab">
                        Session Events
                    </a>
                </li>
                <li class="nav-item">
                    <a :href="`#tabs-carer-logs-${currentSession.id}`" class="nav-link" data-bs-toggle="tab">
                        Carer Logs
                    </a>
                </li>
            </ul>
            <div class="tab-content mt-4">
                <div class="tab-pane active show pt-4 pb-4 px-2 bg-white" style="height: 70dvh; overflow-y: scroll;"
                    :id="`tabs-session-events-${currentSession.id}`">
                    <SessionEventsTimeline :current-event="sessionEvent" :session-id="currentSession.id" timeline-only
                        @updated="fetchDetails" />

                </div>
                <div class="tab-pane" :id="`tabs-carer-logs-${currentSession.id}`">
                    <SessionCarerLogs v-if="carerLogs?.length" :carer-logs="carerLogs" :include-image="true" />
                    <div v-else>
                        <p class="text-center"> No Carer Logs</p>
                    </div>
                </div>
            </div>
        </template>

        <div v-else class="text-center py-4 text-danger">
            Failed to load session details
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, computed, ref, onUnmounted } from 'vue'
import type Session from '@/types/Session'
import SessionCarerLogs from '@/pages/sessions/components/SessionCarerLogs.vue'
import SessionEventsTimeline from '@/components/SessionEventsTimeline/SessionEventsTimeline.vue'
import { useSessionStore } from '@/stores/sessionStore'
import { storeToRefs } from 'pinia'
import { SessionIncludes } from '@/types/query/SessionIncludes'
import SessionService from '@/services/SessionService'
import { useNotificationsStore } from '@/stores/notifications'
import SessionEvent from '@/types/SessionEvent'
import SessionCarerLog from '@/types/SessionCarerLog'

const sessionStore = useSessionStore()
const { currentSession, isFetchingDetails } = storeToRefs(sessionStore)
const { resetCurrentSession } = sessionStore

const { addToastNotification } = useNotificationsStore()

const sessionEvent = ref<SessionEvent | null>(null)
const carerLogs = ref<SessionCarerLog[] | null>(null)

const props = defineProps({
    sessionId: { type: Number, required: true }
})
const fetchDetails = async () => {
    if (! props.sessionId) {
        addToastNotification({
            'type': 'danger',
            'message': 'Error fetching session details: Invalid ID'
        })
        return
    }

    isFetchingDetails.value = true

    const includes: SessionIncludes[] = ['event', 'carerLogs']

    try {
        const { data } = await SessionService.show(props.sessionId, includes)
        currentSession.value = data.data
        sessionEvent.value = data.data.event
        carerLogs.value = data.data.carerLogs

    } catch (err) {
        addToastNotification({
            'type': 'danger',
            'message': 'Error fetching session details:' + err
        })

    } finally {
        isFetchingDetails.value = false
    }
}

onMounted(async () => {
    fetchDetails()
})

// Clean up when component unmounts
onUnmounted(() => {
    resetCurrentSession()
})
</script>