<template>
    <template v-if="routeName">
        <router-link class="fw-bold text-start" :to="{ name: routeName, params: { userId: user.id } }" target="_blank"
            :class="inline ? 'd-flex gap-1' : ''">
            <div>[{{ user.mainProfile?.onlineId ?? 'N/A' }}]</div>
            <span>{{ displayName ?? 'N/A' }}</span>
        </router-link>
    </template>
    <template v-else>
        <div>[{{ user.mainProfile?.onlineId ?? 'N/A' }}]</div>
        <div>{{ displayName ?? 'N/A' }}</div>
    </template>
</template>

<script setup lang="ts">

import User from '@/types/User'
import { computed } from 'vue'
import { RoleNameEnum } from '@/enums/RoleEnum'

const props = defineProps({
    user: {
        type: Object as () => User,
        required: true
    },
    role: {
        type: String,
        required: false
    },
    inline: {
        type: Boolean,
        default: false
    }
})

const component = () => {
    if (routeName) {
        return 'router-link'
    } else {
        return 'div'
    }
}

const displayName = computed(() => {
    return props.user?.mainProfile?.fullName ?? props.user?.name ?? props.user?.email ?? 'N/A'
})

const routeName = computed(() => {
    let route
    switch (props.role) {
        case RoleNameEnum.CARER:
            route = 'carer-show'
            break
        case RoleNameEnum.USER:
            route = 'customer-show'
            break
        default:
            route = null
    }
    return route
})

</script>
