<template>
    <button @click="openModal" class="btn btn-icon btn-primary">
        Proceed
    </button>
    <Teleport to="body">
        <kc-modal :modal-id="modalId" modal-title="Carer Has Arrived">
            <template #default>
                <p class="text-muted mb-4">
                    Please confirm the carer's arrival by uploading a proof of arrival.
                </p>
                <form class="col">
                    <div class="form-group">
                        <label class="font-weight-bold mb-2">Proof of Arrival</label>
                        <div class="upload-container">
                            <ImageInput name="proof_of_arrival" label="Upload File" v-model="selectedImage"
                                class="d-inline-block" />
                        </div>
                        <ErrorMessage :has-error="!!errors.proof_of_arrival" :message="errors.proof_of_arrival || ''" />
                    </div>
                    <!-- Uploaded photo preview -->
                    <div v-if="selectedImage.fileUrl" class="mt-3">
                        <img :src="selectedImage.fileUrl" alt="Preview" class="img-fluid mb-3 rounded" />
                    </div>
                </form>
            </template>
            <template #footer>
                <button class="btn btn-secondary me-2" @click="openModal(false)">Cancel</button>
                <button class="btn btn-primary" @click.prevent="submit">Submit</button>
            </template>
        </kc-modal>
    </Teleport>
</template>

<script setup lang="ts">
import ErrorMessage from '@/components/form/ErrorMessage.vue'
import ImageInput from '@/components/form/ImageInput.vue'
import { useModal } from '@/composable/useModal'
import { SelectedUploadImage } from '@/services/SelectedUploadImage'
import SessionEventService from '@/services/SessionEventService'
import UpdateSessionEventFormData from '@/types/formData/UpdateSessionEventFormData'
import { SessionEventTypeEnum } from '@/types/SessionEventTypeEnum'
import { ref } from 'vue'

const props = defineProps({
    sessionId: { type: Number, required: true }
})

const emits = defineEmits(['success'])

const modalId = 'carer-arrived-form-modal'

const {
    openModal
} = useModal(modalId)

interface Errors {
    proof_of_arrival: string | null
}

const errors = ref<Errors>({
    proof_of_arrival: null
})

const selectedImage = ref<SelectedUploadImage>({
    file: null,
    fileUrl: null
})

const submit = async () => {

    let payload: UpdateSessionEventFormData = {
        session_event_type_id: SessionEventTypeEnum.ID_STATUS_LOCATION_ARRIVED,
    }

    if (selectedImage.value.file) {
        payload.file = selectedImage.value.file
    }

    try {
        const response = await SessionEventService.update(props.sessionId, payload)
        emits('success')
        openModal(false)
    } catch (error) {
        errors.value.proof_of_arrival = 'Failed to upload image. Please try again.'
    }
}

</script>


<style scoped>
.upload-container {
    cursor: pointer;
}
</style>