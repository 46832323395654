<template>
    <span class="badge badge-outline text-orange  w-100">
        {{ label }}
    </span>
</template>

<script setup lang="ts">

import SessionServiceType from '@/types/SessionServiceType'
import { computed } from 'vue'

const props = defineProps({
    sessionServiceTypeId: { type: Number, required: true },
    sessionServiceTypes: { type: Array as () => Array<SessionServiceType>, required: true }
})

const label = computed(() => {
    return props.sessionServiceTypes?.find(findSessionServiceType => findSessionServiceType.id === props.sessionServiceTypeId)?.name
})
</script>
