<template>
    <span class="inline-flex items-center cursor-pointer" v-if="canCopy" role="button" @click="copy">
        <slot></slot>
    </span>
    <span v-else class="inline-flex items-center">
        COPIED!
    </span>
</template>

<script setup lang="ts">
import { copyToClipboard } from '@/composable/useGeneral'
import { computed, ref } from 'vue'
import { useNotificationsStore } from '@/stores/notifications'

const isShowingSuccessText = ref(false)
const setIsShowingSuccessText = (value = true) => isShowingSuccessText.value = value

const props = defineProps({
    data: { type: String },
    resourceName: { type: String, required: false },
    transparent: { type: Boolean, default: false },
    label: { type: String, required: false },
    icon: { type: String, default: 'copy' }
})

const { addToastNotification } = useNotificationsStore()

const canCopy = computed(() => !isShowingSuccessText.value)

const copy = async () => {

    setIsShowingSuccessText(false) // reset

    if (!props.data) {
        addToastNotification({
            type: 'danger',
            message: 'Oops. Nothing copied!'
        })
        return
    }

    const success = await copyToClipboard(props.data)

    if (success) {
        setIsShowingSuccessText()

        const message = props.resourceName
            ? `${props.resourceName} copied!`
            : 'Copied!'

        addToastNotification({
            type: 'success',
            message: message
        })

        setTimeout(() => {
            setIsShowingSuccessText(false)
        }, 1000)
    }
}
</script>

<style scoped>
.hover\:text-blue:hover {
    color: #0054a6;
}
</style>