import { defineStore } from 'pinia'
import { ref, nextTick } from 'vue'
import type { Component } from 'vue'
import Modal from 'bootstrap/js/dist/modal'

interface ModalConfig {
    component: Component
    title?: string
    size?: 'sm' | 'md' | 'lg' | 'xl'
    props?: Record<string, any>
    backdrop?: boolean | 'static'
    keyboard?: boolean
    centered?: boolean
    fullHeight?: boolean
    footer?: Component
}

export const useModalStore = defineStore('modal', () => {
    const isOpen = ref(false)
    const config = ref<ModalConfig | null>(null)
    let bootstrapModal: Modal | null = null

    async function initModal(element: HTMLElement) {
        await nextTick()
        bootstrapModal = new Modal(element)

        // Handle initial state
        if (isOpen.value && bootstrapModal) {
            bootstrapModal.show()
        }

        // Add hidden event listener
        element.addEventListener('hidden.bs.modal', () => {
            hideModal()
        })
    }

    const showModal = async (newConfig: ModalConfig) => {
        config.value = newConfig
        isOpen.value = true


        await nextTick()
        bootstrapModal?.show()
    }

    function hideModal() {
        bootstrapModal?.hide()
        isOpen.value = false
        config.value = null
    }

    function dispose() {
        bootstrapModal?.dispose()
        bootstrapModal = null
    }

    return {
        isOpen,
        config,
        showModal,
        hideModal,
        initModal,
        dispose
    }
})