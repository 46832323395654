import SessionService from "@/services/SessionService"
import MetaPage from "@/types/MetaPage"
import { SessionIncludes } from "@/types/query/SessionIncludes"
import SessionQuery from "@/types/query/SessionQuery"
import Session from "@/types/Session"
import { defineStore } from "pinia"
import { computed, ref, watch, defineEmits } from "vue"

export const useSessionStore = defineStore(
    'sessions',
    () => {

        const sessions = ref<Session[]>()

        const currentSession = ref<Session | null>(null)

        const isFetchingList = ref(false)
        const isFetchingDetails = ref(false)

        const error = ref<string | null>(null)

        const pagination = ref<MetaPage>({
            currentPage: 1,
            lastPage: 1,
            perPage: 10,
            total: 0,
            from: 0,
            to: 0
        })

        // Filters
        const totalItems = ref(0)
        const itemsPerPage = ref(10)

        const fetchSessions = async (query: SessionQuery) => {
            isFetchingList.value = true

            try {
                const { data } = await SessionService.index(query)

                sessions.value = data.data

                pagination.value = {
                    currentPage: data.meta?.current_page,
                    lastPage: data.meta?.last_page,
                    perPage: data.meta?.per_page,
                    total: data.meta?.total,
                    from: data.meta?.from,
                    to: data.meta?.to
                }
            } catch (err) {
                error.value = err instanceof Error ? err.message : 'Failed to load session details'
                console.error('Error fetching sessions:', err)
            } finally {
                isFetchingList.value = false
            }
        }

        const fetchSessionDetails = async (sessionId: number, includes: SessionIncludes) => {
            isFetchingDetails.value = true

        }

        const resetCurrentSession = () => {
            currentSession.value = null
            error.value = null
        }

        function handleSessionCreated(event: { model: Session }) {
            if (pagination.value.currentPage !== 1) return

            if (sessions.value) {
                sessions.value = [event.model, ...sessions.value.slice(0, pagination.value.perPage - 1)]

                // Update pagination
                pagination.value = {
                    ...pagination.value,
                    total: pagination.value.total + 1,
                    to: Math.min(pagination.value.to + 1, pagination.value.perPage),
                    lastPage: Math.ceil((pagination.value.total + 1) / pagination.value.perPage)
                }
            }
        }

        function handleSessionUpdated(event: { model: Session }) {
            if (!sessions.value) return

            const index = sessions.value.findIndex(session => session.id === event.model.id)

            if (index !== -1) {
                sessions.value = [
                    ...sessions.value.slice(0, index),
                    event.model,
                    ...sessions.value.slice(index + 1)
                ]
            }
        }

        function initChannelListeners() {
            window.Echo?.private('backoffice')
                .listen('.SessionCreated', handleSessionCreated)
                .listen('.SessionUpdated', handleSessionUpdated)
        }

        return {
            // State
            isFetchingList,
            isFetchingDetails,

            sessions,
            currentSession,

            totalItems,
            itemsPerPage,
            error,
            pagination,

            // Computed

            // Actions
            fetchSessions,
            fetchSessionDetails,
            resetCurrentSession,
            initChannelListeners
            // $reset,
        }
    }
)