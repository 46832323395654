<template>
    <button class="btn btn-icon  btn-primary "
            @click="openModal()"
            :disabled="disabled"
    >
        <icon name="shopping-cart"/>
    </button>
    <Teleport to="body">
        <kc-modal :modal-id="modalId" modal-size="lg" v-if="!disabled">
            <div class="modal-header">
                <h4 class="modal-title">
                    Add New Order
                </h4>
                <button type="button" class="btn-close" aria-label="Close"
                        @click="openModal(false)"
                ></button>
            </div>
            <div class="modal-body bg-white">
                <ToggleMode :disabled="!session.isReadyForOrder"/>
                <SessionPreview v-if="hasSelectedSessions  && orderPreview && mode === 'custom'" class="mt-3"/>
                <VoucherPointRedemption v-if="hasSelectedSessions" class="mt-3"/>
                <OrderPreview v-if="orderPreview" class="mt-3"/>
                <MarkOrderAsPrimary class="mt-3" v-if="mode === 'custom'"/>
                <OrderCreateFormErrorMessages />
            </div>
            <div class="modal-footer pt-2 border-top">
                <button :id="`${modalId}-close-button`"
                        class="btn btn-light" type="button"
                        @click="openModal(false)"
                >Close
                </button>
                <button @click="handleSubmit" class="btn btn-primary"
                >
                    <span v-if="!isSubmitting">Submit</span>
                    <span v-else>Submitting...</span>
                </button>
            </div>
        </kc-modal>
    </Teleport>
</template>

<script setup lang="ts">

import {useNotificationsStore} from '@/stores/notifications'
import {useModal} from '@/composable/useModal'
import {useCreateOrderStore} from '@/stores/createOrder'
import {watch} from 'vue'
import VoucherPointRedemption from '@/pages/orders/create/components/RedeemVouchersAndPoints.vue'
import {storeToRefs} from 'pinia'
import OrderPreview from '@/pages/orders/create/components/OrderPreview.vue'
import User from '@/types/User'
import ToggleMode from '@/pages/orders/create/components/ToggleMode.vue'
import SessionPreview from '@/pages/orders/create/components/ReviewSessionDetails.vue'
import MarkOrderAsPrimary from '@/pages/orders/create/components/MarkOrderAsPrimary.vue'
import OrderCreateFormErrorMessages from '@/pages/orders/create/components/OrderCreateFormErrorMessages.vue'
import {useShowSessionStore} from '@/stores/showSession'
import Session from '@/types/Session'


interface Props {
  session: Session
  customer: User
  disabled?: boolean
}

// Define props properly
const props = withDefaults(defineProps<Props>(), {
  disabled: false
})

const {addToastNotification} = useNotificationsStore()

const createOrderStore = useCreateOrderStore()
const {
    form,
    mode,
    isSubmitting,
    selectedCustomer,
    hasSelectedSessions,
    orderPreview,
    errors
} = storeToRefs(createOrderStore)

const showSessionStore = useShowSessionStore()
const {fetchSession} = showSessionStore
const {session} = storeToRefs(showSessionStore)

const {
    submit
} = createOrderStore

let {
    modalId,
    openModal,
    onModalShown,
    onModalHidden
} = useModal(`create-order-from-session-${ session.value?.id }-modal`)


const {
    selectMode,
    generatePreview,
    resetAll
} = createOrderStore

onModalShown(() => {
    selectMode('custom')
    setDefaultFormInputs()
    generatePreview()
})

onModalHidden(() => {
    resetAll()
})

const setDefaultFormInputs = () => {
    if (props.customer) {
        selectedCustomer.value = props.customer
    }

    if (session.value?.id) {
        form.value.session_ids.push(session.value.id)
    }
}

const handleSubmit = async () => {
    const {success, data} = await submit()

    if (success) {
        await fetchSession()
        openModal(false)
    }
}

watch(() => mode.value, (newValue, oldValue) => {
    // Reset all if changed modes
    if (!!oldValue) {
        resetAll()
        setDefaultFormInputs()
    }
})


</script>
