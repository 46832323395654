import httpClientV2 from '@/services/HttpClientV2'
import UpdateSessionEventFormData from '@/types/formData/UpdateSessionEventFormData'

export default {

    index(sessionId: number) {
        const endpoint = `backoffice/sessions/${sessionId}/events`
        return httpClientV2.get(endpoint)
    },

    update(sessionId: number, data: UpdateSessionEventFormData) {

        const endpoint = `backoffice/sessions/${sessionId}/events`

        const formData = new FormData()

        formData.append('_method', 'PATCH')

        Object.entries(data).forEach(([key, value]) => {
            if (value !== undefined && value !== null) {
                if (typeof value === 'string' || typeof value === 'number') {
                    formData.append(key, value.toString())
                } else if (value instanceof File) {
                    formData.append(key, value)
                }
            }
        })

        return httpClientV2.post(endpoint, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },

    delete(sessionId: number) {
        const endpoint = `backoffice/sessions/${sessionId}/events`
        return httpClientV2.delete(endpoint)
    }
}